import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import { IImage, IParagraph } from "../models/cmsModels";
import ImageGallery from "../components/shared/ImageGallery/image-gallery";
import SEO from "../components/seo";
import { Container } from "react-bootstrap";
import "../assets/css/main.scss";

const ComponentName = ({data}) => {
  
  const {newsarticle} = data;
  const [galleryImages, setGalleryImages] = useState([] as IImage[]);
  const [imageIndex, setImageIndex] = useState(null as number);
  const [isImageGalleryOpen, setImageGalleryOpen] = useState(false);

    const paragraphStyle: any = (paragraph: IParagraph) => {
      let direction = '';

      switch (paragraph.imagesOrientation) {
        case 'Left': direction = 'row-reverse'; break;
        case 'Right': direction = 'row'; break;
        case 'Top': direction = 'column-reverse'; break;
        case 'Bottom': direction = 'column'; break;
        default: direction = 'column'; break;
      }

      return {
        display: 'flex',
        flexDirection: direction
      }
    }

    const imagesStyle: any = (paragraph: IParagraph) => {
      let direction = '';

      switch (paragraph.imagesOrientation) {
        case 'Left':
        case 'Right': direction = 'column'; break;
        case 'Top': 
        case 'Bottom': direction = 'row'; break;
        default: direction = 'row'; break;
      }

      return {
        display: 'flex',
        flexDirection: direction
      }
    };


    const openImageGallery = (images: IImage[], index: number) => {
        setGalleryImages(images);
        setImageIndex(index);
        setImageGalleryOpen(true);
    };

    const closeImageGallery = () => {
        setGalleryImages([]);
        setImageIndex(null);
        setImageGalleryOpen(false);
    };
  
  return (
      <Layout>
          <SEO title={newsarticle.title} description={newsarticle.description} />
          <Container className="fl-page">
          <section> 
            <div className="section-center">
                <article>
                  <div>
                    <h3>{newsarticle.title}</h3>
                    <br></br>
                      {
                          !isImageGalleryOpen ? <></> :
                          <ImageGallery images={galleryImages} defaultIndex={imageIndex} onClose={() => closeImageGallery()} />
                      }
                      {
                          !newsarticle.paragraphs ? <></> :
                          newsarticle.paragraphs.map((para: IParagraph, p: number) => (
                          <div className="gl-paragraph" style={paragraphStyle(para)}>
                              <div key={p} className='gl-contents' dangerouslySetInnerHTML={{__html: para.contents}}></div>
                              <div className="gl-images" style={imagesStyle(para)}>
                                  {
                                  para.images.map((image, i: number) => (
                                      <>
                                        <img key={i} width={image.width/2} src={image.url} onClick={() => openImageGallery(para.images, i)} title="Click to enlarge"/>
                                      </>
                                  ))
                                  } 
                              </div>
                          </div>
                          ))
                      }
                  </div>
                </article>
                <br></br>
            </div>
          </section>
          </Container>
      </Layout>
  )
}

export const query = graphql`
  query GetSingleNewsarticle($slug: String) {
    newsarticle: strapiNewsarticle(slug: { eq: $slug }) {
      title
      description
      paragraphs {
        contents
        images {
          name
          width
          height
          url
        }
      }
    }
  }
`

export default ComponentName;